import history from './browserHistory';
import { DELIVERY_METHOD_TYPE_MOBILE_TICKETING_ID } from './constants';

export function isFanRequestUsingMobileDelivery(params) {// See RLMTS-4006 for details
  const { seriesSummary } = params;
  let fanRequestIsMobileDelivery = false;
  if (seriesSummary?.OutletEventGroup) {
    const {
      EnableMobileDelivery, LRDomOutletDeliveryMethodID, LRIntlOutletDeliveryMethodID,
    } = seriesSummary.OutletEventGroup;
    if (EnableMobileDelivery && !LRDomOutletDeliveryMethodID && !LRIntlOutletDeliveryMethodID) {
      fanRequestIsMobileDelivery = true;
    }
  }
  return fanRequestIsMobileDelivery;
}

export function getMobileDeliveryMethodFromOutlet(outlet) {
  if (Array.isArray(outlet?.OutletDeliveryMethods)) {
    return outlet.OutletDeliveryMethods.find(x => x.DeliveryMethodTypeID === DELIVERY_METHOD_TYPE_MOBILE_TICKETING_ID);
  }
  return null;
}


export function isUserAuthenticated(session) {
  return (
    session &&
    session.UserID &&
    session.UserID !== 'invalid' &&
    session.UserID !== 'guest' &&
    session.secondsToExpire &&
    session.secondsToExpire > 0
  );
}

/**
 * Determines whether the outlet's login is handled externally.
 *
 * This function checks if the outlet requires external login based on its login type, login URL,
 * and the user's authentication status. If external login is required, it returns `true`;
 * otherwise, it returns `false`.
 *
 * @param {Object} outlet - The outlet object containing login configuration.
 * @param {string} outlet.LoginURL - The URL to redirect for external login.
 * @param {Object} outlet.OutletLoginType - The type of login required for the outlet.
 * @param {string} outlet.OutletLoginType.ConstantValue - The constant value for login type,
 * e.g., 'OUTLET_LOGIN_ALWAYS'.
 * @param {Object} session - The user's session object to check authentication status.
 * @return {boolean} - Returns `true` if the outlet requires external login, otherwise `false`.
 */
export function isOutletLoginHandledExternally(outlet, session) {
  let redirectRequired = false;
  if (
    outlet &&
    outlet.LoginURL &&
    outlet.OutletLoginType &&
    outlet.OutletLoginType.ConstantValue === 'OUTLET_LOGIN_ALWAYS' &&
    !isUserAuthenticated(session)
  ) {
    redirectRequired = true;
  }

  return redirectRequired;
}

export const convertHexToRgb = (hex) => {
  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  return r + ',' + g + ',' + b;
};

export const statuses = [
  {
    StatusID: 1,
    ConstantValue: 'STATUS_REQUEST_PENDING',
  },
  {
    StatusID: 2,
    ConstantValue: 'STATUS_REQUEST_PROCESSING',
  },
  {
    StatusID: 3,
    ConstantValue: 'STATUS_REQUEST_CONFIRMED',
  },
  {
    StatusID: 4,
    ConstantValue: 'STATUS_REQUEST_DENIED',
  },
  {
    StatusID: 5,
    ConstantValue: 'STATUS_REQUEST_VOID',
  },
  {
    StatusID: 6,
    ConstantValue: 'STATUS_ORDER_AWAITING_ASSIGNMENT',
  },
  {
    StatusID: 7,
    ConstantValue: 'STATUS_ORDER_PAID',
  },
  {
    StatusID: 8,
    ConstantValue: 'STATUS_ORDER_ASSIGNED',
  },
  {
    StatusID: 9,
    ConstantValue: 'STATUS_ORDER_FULFILLED',
  },
  {
    StatusID: 10,
    ConstantValue: 'STATUS_ORDER_CLOSED',
  },
  {
    StatusID: 11,
    ConstantValue: 'STATUS_ORDER_CANCELLED',
  },
  {
    StatusID: 13,
    ConstantValue: 'STATUS_GUESTLIST_REQUEST_CONFIRMED',
  },
  {
    StatusID: 14,
    ConstantValue: 'STATUS_GUESTLIST_REQUEST_ASSIGNED',
  },
  {
    StatusID: 15,
    ConstantValue: 'STATUS_GUESTLIST_REQUEST_DELIVERED',
  },
  {
    StatusID: 16,
    ConstantValue: 'STATUS_GUESTLIST_REQUEST_APPROVED',
  },
  {
    StatusID: 17,
    ConstantValue: 'STATUS_GUESTLIST_REQUEST_PAID_IN_FULL',
  },
  {
    StatusID: 18,
    ConstantValue: 'STATUS_GUESTLIST_REQUEST_PENDING',
  },
  {
    StatusID: 19,
    ConstantValue: 'STATUS_GUESTLIST_REQUEST_DECLINED',
  },
  {
    StatusID: 20,
    ConstantValue: 'STATUS_ORDER_FAILED_PAYMENT',
  },
  {
    StatusID: 21,
    ConstantValue: 'STATUS_ORDER_APPROVED',
  },
  {
    StatusID: 22,
    ConstantValue: 'STATUS_ORDEREVENT_PROCESSING',
  },
  {
    StatusID: 23,
    ConstantValue: 'STATUS_ORDEREVENT_ASSIGNED',
  },
  {
    StatusID: 24,
    ConstantValue: 'STATUS_ORDEREVENT_CANCELED',
  },
  {
    StatusID: 30,
    ConstantValue: 'STATUS_GUESTLIST_REQUEST_CANCELED',
  },
  {
    StatusID: 31,
    ConstantValue: 'STATUS_GUESTLIST_REQUEST_EVENT_APPROVED',
  },
  {
    StatusID: 32,
    ConstantValue: 'STATUS_GUESTLIST_REQUEST_EVENT_CANCELED',
  },
  {
    StatusID: 33,
    ConstantValue: 'STATUS_GUESTLIST_REQUEST_EVENT_DECLINED',
  },
  {
    StatusID: 34,
    ConstantValue: 'STATUS_GUESTLIST_REQUEST_EVENT_PENDING',
  },
  {
    StatusID: 35,
    ConstantValue: 'STATUS_REQUEST_DENIED_INVENTORY',
  },
  {
    StatusID: 36,
    ConstantValue: 'STATUS_REQUEST_CONFIRMED_ALTERNATE',
  },
];

export const isCreditCardExpired = (expiresDate) => {
  let isExpired = false;
  if (expiresDate) {
    const todaysDate = new Date();
    const currentYear = Number(todaysDate.getFullYear());
    const currentMonth = Number(todaysDate.getMonth() + 1);

    const expirationYear = Number(expiresDate.split('-')[0]);
    const expirationMonth = Number(expiresDate.split('-')[1]);

    if (expirationYear < currentYear) {
      isExpired = true;
    } else {
      if (expirationYear === currentYear) {
        if (expirationMonth < currentMonth) {
          isExpired = true;
        }
      }
    }
  }

  return isExpired;
};
export const getCreditCartExpirationDateMessage = (expiresDate) => {
  const todaysDate = new Date();
  const currentYear = Number(todaysDate.getFullYear());
  const currentMonth = Number(todaysDate.getMonth() + 1);

  const expirationYear = Number(expiresDate.split('-')[0]);
  const expirationMonth = Number(expiresDate.split('-')[1]);

  let expirationMessage;

  if (isCreditCardExpired(expiresDate)) {
    expirationMessage = 'Expired';
  } else {
    if (expirationYear === currentYear) {
      if (
        expirationMonth === currentMonth ||
        expirationMonth - 1 === currentMonth ||
        expirationMonth - 2 === currentMonth
      ) {
        expirationMessage = 'Expiring Soon';
      }
    }
  }

  // console.log("todaysDate ", todaysDate);
  // console.log("currentYear ", currentYear);

  // console.log("currentMonth ", currentMonth);

  // console.log("expirationDate ", expiresDate);
  // console.log("expirationYear ", expirationYear);
  // console.log("expirationMonth ", expirationMonth);
  // console.log("expirationMessage ", expirationMessage);
  // console.log("===================");
  return expirationMessage;
};

export const getYearsArray = (limit = 30) => {
  const now = new Date().getUTCFullYear() + 1;
  const years = Array(now - (now - limit)).fill('').map((v, idx) => now - idx);
  return years;
};

export const calculateSeriesStatuses = (OutletEventGroup = {}) => {
  //console.log("series inside calc OutletEventGroup", OutletEventGroup);
  let seriesIsOpen = false;
  let seriesIsVisible = false;
  let seriesIsClosed = false;

  if (OutletEventGroup) {
    const { SeriesVisibleDate, SeriesOpenDate, SeriesClosedDate } =
      OutletEventGroup;
    if (SeriesVisibleDate && new Date(SeriesVisibleDate) <= new Date()) {
      seriesIsVisible = true;
    }
    if (SeriesOpenDate && new Date(SeriesOpenDate) <= new Date()) {
      seriesIsOpen = true;
    }
    if (SeriesClosedDate && new Date(SeriesClosedDate) <= new Date()) {
      seriesIsClosed = true;
    }
    //Morning standup discussion 12-16-2021 - "all dates must be set for status to apply"
    if (seriesIsClosed) {
      seriesIsOpen = false;
      seriesIsVisible = false;
    }
  }

  return { seriesIsOpen, seriesIsVisible, seriesIsClosed };
};

export const lineItemsNormalizedByOutletEventItem = ({
  OutletEventItems,
  cart,
  includeAllOutletEventItems,
}) => {
  let lineItemsNormalizedByOEI = [];

  if (OutletEventItems && cart.lineItems) {
    OutletEventItems.map((oei) => {
      if (oei.PriceLevels) {
        cart.lineItems.map((li) => {
          oei.PriceLevels.map((pl) => {
            const PriceLevelID = li.priceLevelID
              ? li.priceLevelID
              : li.PriceLevelID;
            if (Number(pl.PriceLevelID) === Number(PriceLevelID)) {
              const oeiEstablished = lineItemsNormalizedByOEI.find(
                (x) => x.OutletEventItemID === oei.OutletEventItemID,
              );
              const oeiIndex = lineItemsNormalizedByOEI.findIndex(
                (x) => x.OutletEventItemID === oei.OutletEventItemID,
              );
              if (oeiEstablished) {
                lineItemsNormalizedByOEI[oeiIndex].lineItems.push({
                  ...pl,
                  ...li,
                });
              } else {
                lineItemsNormalizedByOEI.push({
                  ...oei,
                  lineItems: [{ ...pl, ...li }],
                  OutletEventItemName: oei.Name,
                  OutletEventItemID: oei.OutletEventItemID,
                  OutletEventItemDescription: oei.Description,
                });
              }
            }

            return null;
          });
          return null;
        });
      }
      return null;
    });

    if (includeAllOutletEventItems) {
      let lineItemsNormalizedByOEI2 = [...lineItemsNormalizedByOEI];
      OutletEventItems.map((oei) => {
        let oeiFound = lineItemsNormalizedByOEI2.find(
          (n) => n.OutletEventItemID == oei.OutletEventItemID,
        );
        if (!oeiFound) {
          let lineItems = [];
          oei.PriceLevels.map((pl) => {
            lineItems.push(pl);
          });
          lineItemsNormalizedByOEI2.push({
            ...oei,
            lineItems: null,
            OutletEventItemName: oei.Name,
            OutletEventItemID: oei.OutletEventItemID,
            OutletEventItemDescription: oei.Description,
          });
        }
      });
      lineItemsNormalizedByOEI = lineItemsNormalizedByOEI2;
    }
  }
  return lineItemsNormalizedByOEI;
};

export const htmlHasValidContent = (html = null) => {
  //RLMTS-2565 - Outlet Description still displays even if the admin html editor is showing as blank
  //html editor in the admin saves stray tags to the databse even if content is seemingly deleted
  let valid = true;
  switch (html) {
    case 0:
    case false:
    case null:
    case undefined:
    case '':
    case '&nbsp;':
    case '&nbsp;&nbsp;':
    case '&nbsp; &nbsp;':
    case '<p>':
    case '</p>':
    case '<br>':
    case '<p></p>':
    case '<p><br></p>':
    case '<p>&nbsp;</p>':
    case '<p>&nbsp;&nbsp;</p>':
    case '<p>&nbsp; &nbsp;</p>':
    case '<p><br><br></p>':
    case `<p><br></p>\n<p><br></p>`:
    case `<p><br></p>\n\n<p><br></p>`:
    case `<p><br></p>&nbsp;\n<p><br></p>`:
    case `<p><br></p>\n&nbsp;<p><br></p>`:
    case `<p><br></p>&nbsp;\n&nbsp;<p><br></p>`:
      valid = false;
      break;
  }
  return valid;
};

export const normalizeAddress = (address, format) => {

  if (address && Object.keys(address).length) {
    //the api serves an address in three formats:

    //1. user address table
    // AddressID: String,
    // AddressLineOne: String,
    // AddressLineTwo: String,
    // City: String,
    // CountryID: Int,
    // First: String,
    // Last: String,
    // Phone: String,
    // State: String,
    // Zip: String

    //2. customer order address
    // Address1: ""
    // Address2: null
    // City: ""
    // Country: "1"
    // EmailAddress: ""
    // FirstName: ""
    // LastName: ""
    // Phone: null
    // State: ""
    // ZipCode: null

    //3. guest list request
    //     RequestorEmail,
    //     RequestorFirstName,
    //     RequestorLastName,
    //     GuestPhone,
    //     ShippingAddressLineOne,
    //     ShippingAddressLineTwo,
    //     ShippingCity,
    //     ShippingCompanyName,
    //     ShippingCountry,
    //     ShippingState,
    //     ShippingZip,

    //as a result we must normalize it for display
    //we pick user address table format because that was the first table created so yeah whatever
    let {
      AddressID,
      AddressUUID,
      AddressLineOne,
      AddressLineTwo,
      City,
      CountryID,
      First,
      Last,
      Phone,
      State,
      Zip,
      CompanyName,

      Address1,
      Address2,
      Country,
      EmailAddres,
      FirstName,
      LastName,
      ZipCode,

      RequestorEmail,
      RequestorFirstName,
      RequestorLastName,
      GuestPhone,
      ShippingAddressLineOne,
      ShippingAddressLineTwo,
      ShippingCity,
      ShippingCompanyName,
      ShippingCountry,
      ShippingState,
      ShippingZip,
    } = address;

    if (format === 'fanRequest') {
      return {
        FirstName: First || '',
        LastName: Last || '',
        CompanyName: CompanyName || '',
        Address1: AddressLineOne || '',
        Address2: AddressLineTwo || '',
        CountryID: CountryID || Country || '',
        City: City || '',
        State: State || '',
        Phone: Phone || '',
        ZipCode: address.Zip,
        AddressID: address.AddressID || '',
        AddressUUID: address.AddressUUID || null,
      };
    }
    return {
      CompanyName: CompanyName || ShippingCompanyName || '',
      AddressLineOne: AddressLineOne || Address1 || ShippingAddressLineOne || '',
      AddressLineTwo: AddressLineTwo || Address2 || ShippingAddressLineTwo || '',
      First: First || FirstName || RequestorFirstName || '',
      Last: Last || LastName || RequestorLastName || '',
      Zip: Zip || ZipCode || ShippingZip || '',
      CountryID: CountryID || Country || ShippingCountry || '',
      City: City || ShippingCity || '',
      State: State || ShippingState || '',
      Phone: Phone || GuestPhone || '',
      EmailAddres: EmailAddres || RequestorEmail || '',

      AddressID: AddressID || '',
      AddressUUID: AddressUUID || null,
    };
  } else {
    return null;
  }
};

export const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}-${phoneNumber.slice(6, 10)}`;
};

export const submitWhenEnterIsPressed = (e, cb) => {
  if (e.key === 'Enter' && e.shiftKey === false) {
    e.preventDefault();
    cb();
  }
};

export const handlePromise = (promise) => {
  // example:
  // const [outletInfo, outletError] = await handlePromise(getOutlet());
  // if (outletError) throw new Error("Could not fetch outlet details");
  // const [events, eventsError] = await handlePromise(
  //   getEventList({ OutletID })
  // );
  // if (eventsError) throw new Error("Could not fetch event list ");
  // let promises = friendIDs.map((id) => {
  //     return fetchJSON(`/users/${id}`);
  // });
  // let friends = await Promise.all(promises);
  return promise.then((data) => [data, undefined]).catch((error) => Promise.resolve([undefined, error]));
};

export function formatCurrency(
  number,
  format = {
    style: 'currency',
    currency: 'USD',
  },
) {
  if (number || number === 0) {
    return new Intl.NumberFormat('en-US', format).format(number);
  }
  return number;
}

export function formatDate(date, customFormat) {
  const defaultFormat = {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  };
  let format = {
    timeZone: 'America/New_York',
  };
  if (customFormat) {
    format = {
      ...format,
      ...customFormat,
    };
  } else {
    format = {
      ...format,
      ...defaultFormat,
    };
  }

  return date ? new Date(date).toLocaleDateString('en-US', format) : '';
}

export function formatTime(time, customFormat) {
  const defaultFormat = {
    hour: 'numeric',
    minute: 'numeric',
  };
  let format = {
    timeZone: 'America/New_York',
  };
  if (customFormat) {
    format = {
      ...format,
      ...customFormat,
    };
  } else {
    format = {
      ...format,
      ...defaultFormat,
    };
  }

  return time ? new Date(time).toLocaleTimeString('en-US', format) : '';
}

export function isObject(x) {
  if (typeof x === 'object' && !Array.isArray(x) && x !== null) {
    return true;
  } else {
    return false;
  }
}

export function goToUrl(url, config) {
  if (url) {
    if (config && isObject(config)) {
      const { replace, outside } = config;

      if (replace) {
        history.replace(url, []);
        return;
      } else if (outside) {
        window.location.replace(url);
        return;
      } else {
      }
    }
    history.push(url);
  }
  return false;
}

export function createUUID() {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  );
}

export function toOrdinal(num) {
  switch (+num.toString().split('').pop()) {
    case 1:
      return num + 'st';

    case 2:
      return num + 'nd';

    case 3:
      return num + 'rd';

    default:
      return num + 'th';
  }
}

export function getUnitedStates() {
  var states = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'American Samoa', abbreviation: 'AS' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'District Of Columbia', abbreviation: 'DC' },
    { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Guam', abbreviation: 'GU' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Marshall Islands', abbreviation: 'MH' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Northern Mariana Islands', abbreviation: 'MP' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Palau', abbreviation: 'PW' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Puerto Rico', abbreviation: 'PR' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virgin Islands', abbreviation: 'VI' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
    { name: 'US Minor Outlying Islands', abbreviation: 'UM' },
    { name: 'US Armed Forces–Americas', abbreviation: 'AA' },
    { name: 'US Armed Forces – Europe', abbreviation: 'AE' },
    { name: 'US Armed Forces – Pacific', abbreviation: 'AP' },
  ];
  return states;
}

export function getCanadaProvinces() {
  var provinces = [
    { name: 'Alberta', abbreviation: 'AB', id: 1 },
    { name: 'British Columbia', abbreviation: 'BC', id: 2 },
    { name: 'Manitoba', abbreviation: 'MB', id: 3 },
    { name: 'Nunavut', abbreviation: 'NU', id: 11 },
    { name: 'New Brunswick', abbreviation: 'NB', id: 4 },
    { name: 'Newfoundland and Labrador', abbreviation: 'NL', id: 5 },
    { name: 'Northwest Territories', abbreviation: 'NT', id: 13 },
    { name: 'Nova Scotia', abbreviation: 'NS', id: 6 },
    { name: 'Ontario', abbreviation: 'ON', id: 7 },
    { name: 'Prince Edward Island', abbreviation: 'PE', id: 8 },
    { name: 'Quebec', abbreviation: 'QC', id: 9 },
    { name: 'Saskatchewan', abbreviation: 'SK', id: 10 },
    { name: 'Yukon', abbreviation: 'YT', id: 12 },
  ];
  return provinces;
}

export function getCountryList() {
  var countries = [
    { name: 'United States', abbreviation: 'US', id: 1 },
    { name: 'Canada', abbreviation: 'CA', id: 2 },
    // { name: "Afghanistan", abbreviation: "AF", id: 3 },
    // { name: "Albania", abbreviation: "AL", id: 4 },
    // { name: "Algeria", abbreviation: "DZ", id: 5 },
    // { name: "Andorra", abbreviation: "AD", id: 6 },
    // { name: "Angola", abbreviation: "AO", id: 7 },
    // { name: "Anguilla", abbreviation: "AI", id: 8 },
    // { name: "Antarctica", abbreviation: "AQ", id: 9 },
    // { name: "Antigua And Barbuda", abbreviation: "AG", id: 10 },
    // { name: "Argentina", abbreviation: "AR", id: 11 },
    // { name: "Armenia", abbreviation: "AM", id: 12 },
    // { name: "Aruba", abbreviation: "AW", id: 13 },
    // { name: "Australia", abbreviation: "AU", id: 14 },
    // { name: "Austria", abbreviation: "AT", id: 15 },
    // { name: "Azerbaijan", abbreviation: "AZ", id: 16 },
    // { name: "Bahamas", abbreviation: "BS", id: 17 },
    // { name: "Bahrain", abbreviation: "BH", id: 18 },
    // { name: "Bangladesh", abbreviation: "BD", id: 19 },
    // { name: "Barbados", abbreviation: "BB", id: 20 },
    // { name: "Belgium", abbreviation: "BE", id: 21 },
    // { name: "Belize", abbreviation: "BZ", id: 22 },
    // { name: "Benin", abbreviation: "BJ", id: 23 },
    // { name: "Bermuda", abbreviation: "BM", id: 24 },
    // { name: "Bhutan", abbreviation: "BT", id: 25 },
    // { name: "Bolivia", abbreviation: "BO", id: 26 },
    // { name: "Bosnia And Herzegovina", abbreviation: "BA", id: 27 },
    // { name: "Botswana", abbreviation: "BW", id: 28 },
    // { name: "Bouvet Island", abbreviation: "BV", id: 29 },
    // { name: "Brazil", abbreviation: "BR", id: 30 },
    // { name: "British Indian Ocean Territory", abbreviation: "IO", id: 31 },
    // { name: "Brunei", abbreviation: "BN", id: 32 },
    // { name: "Bulgaria", abbreviation: "BG", id: 33 },
    // { name: "Burkina Faso", abbreviation: "BF", id: 34 },
    // { name: "Burundi", abbreviation: "BI", id: 35 },
    // { name: "Cambodia", abbreviation: "KH", id: 36 },
    // { name: "Cameroon", abbreviation: "CM", id: 37 },
    // { name: "Cape Verde", abbreviation: "CV", id: 38 },
    // { name: "Cayman Islands", abbreviation: "KY", id: 39 },
    // { name: "Central African Republic", abbreviation: "CF", id: 40 },
    // { name: "Chad", abbreviation: "TD", id: 41 },
    // { name: "Chile", abbreviation: "CL", id: 42 },
    // { name: "China", abbreviation: "CN", id: 43 },
    // { name: "Christmas Island", abbreviation: "CX", id: 44 },
    // { name: "Cocos (keeling) Islands", abbreviation: "CC", id: 45 },
    // { name: "Colombia", abbreviation: "CO", id: 46 },
    // { name: "Comoros", abbreviation: "KM", id: 47 },
    // { name: "Congo", abbreviation: "CG", id: 48 },
    // {
    //   name: "Congo The Democratic Republic Of The",
    //   abbreviation: "CD",
    //   id: 49
    // },
    // { name: "Cook Islands", abbreviation: "CK", id: 50 },
    // { name: "Costa Rica", abbreviation: "CR", id: 51 },
    // { name: "Cote D'ivoire", abbreviation: "CI", id: 52 },
    // { name: "Croatia", abbreviation: "HR", id: 53 },
    // { name: "Cuba", abbreviation: "CU", id: 54 },
    // { name: "Cyprus", abbreviation: "CY", id: 55 },
    // { name: "Czech Republic", abbreviation: "CZ", id: 56 },
    // { name: "Denmark", abbreviation: "DK", id: 57 },
    // { name: "Djibouti", abbreviation: "DJ", id: 58 },
    // { name: "Dominica", abbreviation: "DM", id: 59 },
    // { name: "Dominican Republic", abbreviation: "DO", id: 60 },
    // { name: "Ecuador", abbreviation: "EC", id: 61 },
    // { name: "Egypt", abbreviation: "EG", id: 62 },
    // { name: "El Salvador", abbreviation: "SV", id: 63 },
    // { name: "Equatorial Guinea", abbreviation: "GQ", id: 64 },
    // { name: "Eritrea", abbreviation: "ER", id: 65 },
    // { name: "Estonia", abbreviation: "EE", id: 66 },
    // { name: "Ethiopia", abbreviation: "ET", id: 67 },
    // { name: "Falkland Islands (malvinas)", abbreviation: "FK", id: 68 },
    // { name: "Faroe Islands", abbreviation: "FO", id: 69 },
    // { name: "Fiji", abbreviation: "FJ", id: 70 },
    // { name: "Finland", abbreviation: "FI", id: 71 },
    // { name: "France", abbreviation: "FR", id: 72 },
    // { name: "French Guiana", abbreviation: "GF", id: 73 },
    // { name: "French Polynesia", abbreviation: "PF", id: 74 },
    // { name: "French Southern Territories", abbreviation: "TF", id: 75 },
    // { name: "Gabon", abbreviation: "GA", id: 76 },
    // { name: "Gambia", abbreviation: "GM", id: 77 },
    // { name: "Georgia", abbreviation: "GE", id: 78 },
    // { name: "Germany", abbreviation: "DE", id: 79 },
    // { name: "Ghana", abbreviation: "GH", id: 80 },
    // { name: "Gibraltar", abbreviation: "GI", id: 81 },
    // { name: "Greece", abbreviation: "GR", id: 82 },
    // { name: "Greenland", abbreviation: "GL", id: 83 },
    // { name: "Grenada", abbreviation: "GD", id: 84 },
    // { name: "Guadeloupe", abbreviation: "GP", id: 85 },
    // { name: "Guam", abbreviation: "GU", id: 86 },
    // { name: "Guatemala", abbreviation: "GT", id: 87 },
    // { name: "Guernsey", abbreviation: "GG", id: 88 },
    // { name: "Guinea", abbreviation: "GN", id: 89 },
    // { name: "Guinea-bissau", abbreviation: "GW", id: 90 },
    // { name: "Guyana", abbreviation: "GY", id: 91 },
    // { name: "Haiti", abbreviation: "HT", id: 92 },
    // { name: "Heard Island And McDonald Islands", abbreviation: "HM", id: 93 },
    // { name: "Holy See (Vatican City State)", abbreviation: "VA", id: 94 },
    // { name: "Honduras", abbreviation: "HN", id: 95 },
    // { name: "Hong Kong", abbreviation: "HK", id: 96 },
    // { name: "Hungary", abbreviation: "HU", id: 97 },
    // { name: "Iceland", abbreviation: "IS", id: 98 },
    // { name: "India", abbreviation: "IN", id: 99 },
    // { name: "Indonesia", abbreviation: "ID", id: 100 },
    // { name: "Ireland", abbreviation: "IE", id: 101 },
    // { name: "Isle Of Man", abbreviation: "IM", id: 102 },
    // { name: "Israel", abbreviation: "IL", id: 103 },
    // { name: "Italy", abbreviation: "IT", id: 104 },
    // { name: "Jamaica", abbreviation: "JM", id: 105 },
    // { name: "Japan", abbreviation: "JP", id: 106 },
    // { name: "Jersey", abbreviation: "JE", id: 107 },
    // { name: "Jordan", abbreviation: "JO", id: 108 },
    // { name: "Kazakhstan", abbreviation: "KZ", id: 109 },
    // { name: "Kenya", abbreviation: "KE", id: 110 },
    // { name: "Kiribati", abbreviation: "KI", id: 111 },
    // { name: "Kuwait", abbreviation: "KW", id: 112 },
    // { name: "Kyrgyzstan", abbreviation: "KG", id: 113 },
    // { name: "Lao People's Democratic Republic", abbreviation: "LA", id: 114 },
    // { name: "Latvia", abbreviation: "LV", id: 115 },
    // { name: "Lesotho", abbreviation: "LS", id: 116 },
    // { name: "Liberia", abbreviation: "LR", id: 117 },
    // { name: "Liechtenstein", abbreviation: "LI", id: 118 },
    // { name: "Lithuania", abbreviation: "LT", id: 119 },
    // { name: "Luxembourg", abbreviation: "LU", id: 120 },
    // { name: "Macao", abbreviation: "MO", id: 121 },
    // {
    //   name: "Macedonia The Former Yugoslav Republic Of",
    //   abbreviation: "MK",
    //   id: 122
    // },
    // { name: "Madagascar", abbreviation: "MG", id: 123 },
    // { name: "Malawi", abbreviation: "MW", id: 124 },
    // { name: "Malaysia", abbreviation: "MY", id: 125 },
    // { name: "Maldives", abbreviation: "MV", id: 126 },
    // { name: "Mali", abbreviation: "ML", id: 127 },
    // { name: "Malta", abbreviation: "MT", id: 128 },
    // { name: "Martinique", abbreviation: "MQ", id: 129 },
    // { name: "Mauritania", abbreviation: "MR", id: 130 },
    // { name: "Mauritius", abbreviation: "MU", id: 131 },
    // { name: "Mayotte", abbreviation: "YT", id: 132 },
    // { name: "Mexico", abbreviation: "MX", id: 133 },
    // { name: "Moldova Republic Of", abbreviation: "MD", id: 134 },
    // { name: "Monaco", abbreviation: "MC", id: 135 },
    // { name: "Mongolia", abbreviation: "MN", id: 136 },
    // { name: "Montenegro", abbreviation: "ME", id: 137 },
    // { name: "Montserrat", abbreviation: "MS", id: 138 },
    // { name: "Morocco", abbreviation: "MA", id: 139 },
    // { name: "Mozambique", abbreviation: "MZ", id: 140 },
    // { name: "Myanmar", abbreviation: "MM", id: 141 },
    // { name: "Namibia", abbreviation: "NA", id: 142 },
    // { name: "Nauru", abbreviation: "NR", id: 143 },
    // { name: "Nepal", abbreviation: "NP", id: 144 },
    // { name: "Netherlands", abbreviation: "NL", id: 145 },
    // { name: "Netherlands Antilles", abbreviation: "AN", id: 146 },
    // { name: "New Caledonia", abbreviation: "NC", id: 147 },
    // { name: "New Zealand", abbreviation: "NZ", id: 148 },
    // { name: "Nicaragua", abbreviation: "NI", id: 149 },
    // { name: "Niger", abbreviation: "NE", id: 150 },
    // { name: "Nigeria", abbreviation: "NG", id: 151 },
    // { name: "Niue", abbreviation: "NU", id: 152 },
    // { name: "Norfolk Island", abbreviation: "NF", id: 153 },
    // { name: "Norway", abbreviation: "NO", id: 154 },
    // { name: "Oman", abbreviation: "OM", id: 155 },
    // { name: "Pakistan", abbreviation: "PK", id: 156 },
    // { name: "Palestinian Territory Occupied", abbreviation: "PS", id: 157 },
    // { name: "Panama", abbreviation: "PA", id: 158 },
    // { name: "Papua New Guinea", abbreviation: "PG", id: 159 },
    // { name: "Paraguay", abbreviation: "PY", id: 160 },
    // { name: "Peru", abbreviation: "PE", id: 161 },
    // { name: "Philippines", abbreviation: "PH", id: 162 },
    // { name: "Pitcairn", abbreviation: "PN", id: 163 },
    // { name: "Poland", abbreviation: "PL", id: 164 },
    // { name: "Portugal", abbreviation: "PT", id: 165 },
    // { name: "Qatar", abbreviation: "QA", id: 166 },
    // { name: "Reunion", abbreviation: "RE", id: 167 },
    // { name: "Romania", abbreviation: "RO", id: 168 },
    // { name: "Russia", abbreviation: "RU", id: 169 },
    // { name: "Rwanda", abbreviation: "RW", id: 170 },
    // { name: "Saint Helena", abbreviation: "SH", id: 171 },
    // { name: "Saint Kitts And Nevis", abbreviation: "KN", id: 172 },
    // { name: "Saint Lucia", abbreviation: "LC", id: 173 },
    // { name: "Saint Pierre And Miquelon", abbreviation: "PM", id: 174 },
    // { name: "Saint Vincent And the Grenadines", abbreviation: "VC", id: 175 },
    // { name: "Samoa", abbreviation: "WS", id: 176 },
    // { name: "San Marino", abbreviation: "SM", id: 177 },
    // { name: "Sao Tome And Principe", abbreviation: "ST", id: 178 },
    // { name: "Saudi Arabia", abbreviation: "SA", id: 179 },
    // { name: "Senegal", abbreviation: "SN", id: 180 },
    // { name: "Serbia", abbreviation: "RS", id: 181 },
    // { name: "Seychelles", abbreviation: "SC", id: 182 },
    // { name: "Sierra Leone", abbreviation: "SL", id: 183 },
    // { name: "Singapore", abbreviation: "SG", id: 184 },
    // { name: "Slovakia", abbreviation: "SK", id: 185 },
    // { name: "Slovenia", abbreviation: "SI", id: 186 },
    // { name: "Solomon Islands", abbreviation: "SB", id: 187 },
    // { name: "South Africa", abbreviation: "ZA", id: 188 },
    // {
    //   name: "South Georgia And The South Sandwich Islands",
    //   abbreviation: "GS",
    //   id: 189
    // },
    // { name: "South Korea", abbreviation: "KR", id: 190 },
    // { name: "Spain", abbreviation: "ES", id: 191 },
    // { name: "Sri Lanka", abbreviation: "LK", id: 192 },
    // { name: "Suriname", abbreviation: "SR", id: 193 },
    // { name: "Svalbard And Jan Mayen", abbreviation: "SJ", id: 194 },
    // { name: "Swaziland", abbreviation: "SZ", id: 195 },
    // { name: "Sweden", abbreviation: "SE", id: 196 },
    // { name: "Switzerland", abbreviation: "CH", id: 197 },
    // { name: "Taiwan", abbreviation: "TW", id: 198 },
    // { name: "Tajikistan", abbreviation: "TJ", id: 199 },
    // { name: "Tanzania", abbreviation: "TZ", id: 200 },
    // { name: "Thailand", abbreviation: "TH", id: 201 },
    // { name: "Togo", abbreviation: "TG", id: 202 },
    // { name: "Tokelau", abbreviation: "TK", id: 203 },
    // { name: "Tonga", abbreviation: "TO", id: 204 },
    // { name: "Trinidad and Tobago", abbreviation: "TT", id: 205 },
    // { name: "Tunisia", abbreviation: "TN", id: 206 },
    // { name: "Turkey", abbreviation: "TR", id: 207 },
    // { name: "Turkmenistan", abbreviation: "TM", id: 208 },
    // { name: "Tuvalu", abbreviation: "TV", id: 209 },
    // { name: "Uganda", abbreviation: "UG", id: 210 },
    // { name: "Ukraine", abbreviation: "UA", id: 211 },
    // { name: "United Arab Emirates", abbreviation: "AE", id: 212 },
    // { name: "United Kingdom", abbreviation: "GB", id: 213 },
    // { name: "Uruguay", abbreviation: "UY", id: 214 },
    // { name: "Uzbekistan", abbreviation: "UZ", id: 215 },
    // { name: "Vanuatu", abbreviation: "VU", id: 216 },
    // { name: "Venezuela", abbreviation: "VE", id: 217 },
    // { name: "Vietnam", abbreviation: "VN", id: 218 },
    // { name: "Virgin Islands British", abbreviation: "VG", id: 219 },
    // { name: "Wallis And Futuna", abbreviation: "WF", id: 220 },
    // { name: "Yugoslavia", abbreviation: "YU", id: 221 },
    // { name: "Zambia", abbreviation: "ZM", id: 222 }
  ];
  return countries;
}

/**
 * Returns a shipping address object payload used during checkout
 * @param {*} shippingAddress shipping address object
 * @return {object}
 */
export const getShippingAddressForCheckout = (shippingAddress) => {
  if (!shippingAddress) {
    return {}
  }
  return {
    firstName: shippingAddress.First,
    lastName: shippingAddress.Last,
    companyName: shippingAddress.CompanyName,
    address1: shippingAddress.AddressLineOne,
    address2: shippingAddress.AddressLineTwo,
    city: shippingAddress.City,
    state: shippingAddress.State,
    country: shippingAddress.CountryID,
    zipcode: shippingAddress.Zip,
    phone: shippingAddress.Phone,
  };
};

/**
 * Transforms a list of payment objects into a standardized format for checkout.
 *
 * This function processes an array of payment objects, extracting and renaming specific fields
 * to create a normalized structure suitable for checkout operations.
 *
 * @function getPaymentInfoForCheckout
 * @param {Array<Object>} paymentList - An array of payment objects to be transformed.
 * @param {number} [paymentList[].AuthorizePaymentMethodID] - The ID of the payment method in the authorization system.
 * @param {string} [paymentList[].CardHolderName] - The name of the cardholder.
 * @param {boolean} [paymentList[].DefaultFlag] - Indicates if the payment method is the default.
 * @param {string} [paymentList[].Description] - A description of the payment method.
 * @param {string} [paymentList[].DisabledAt] - Timestamp when the payment method was disabled.
 * @param {string} [paymentList[].Expiration] - Expiration date of the payment method.
 * @param {string} [paymentList[].LastFour] - The last four digits of the card number.
 * @param {number} [paymentList[].PaymentMethodID] - The unique ID of the payment method.
 * @param {string} [paymentList[].PaymentMethodUUID] - The UUID of the payment method.
 * @param {number} [paymentList[].Sort] - Sorting order for the payment methods.
 * @param {string} [paymentList[].Type] - The type of the payment method (e.g., credit card, ACH).
 * @param {string} [paymentList[].createdAt] - Timestamp when the payment method was created.
 * @param {string} [paymentList[].deletedAt] - Timestamp when the payment method was deleted.
 * @param {string} [paymentList[].updatedAt] - Timestamp when the payment method was last updated.
 * @return {Array<Object>} A transformed array of payment objects, or `undefined` if the input is falsy.
 */
export const getPaymentInfoForCheckout = (paymentList = []) => {
  if (!Array.isArray(paymentList)) {
    return []; // Ensure function always returns an array
  }

  return paymentList?.map((p) => {
    return {
      authorizePaymentMethodId: p.AuthorizePaymentMethodID,
      cardHolderName: p.CardHolderName,
      defaultFlag: p.DefaultFlag,
      description: p.Description,
      disabledAt: p.DisabledAt,
      expiration: p.Expiration,
      lastFour: p.LastFour,
      paymentMethodId: p.PaymentMethodID,
      paymentMethodUUID: p.PaymentMethodUUID,
      sort: p.Sort,
      type: p.Type,
      createdAt: p.createdAt,
      deletedAt: p.deletedAt,
      updatedAt: p.updatedAl,
    };
  });
};
