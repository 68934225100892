/* eslint-disable require-jsdoc */
import store from '../../store';
import { addToast } from '../toast/actions';
import { api } from '../api/actions';
import { getPaymentInfoForCheckout, getShippingAddressForCheckout } from '../../utils/utilities';
import { GTService } from '../../utils/gt-service.api';

const gtService = new GTService();

export function resetGuestListCart() {
  store.dispatch({
    type: 'RESET_GUEST_REQUEST_CART',
  });
}

export function setGuestListCart(cart) {
  store.dispatch({
    type: 'SET_GUEST_REQUEST_CART',
    cart,
  });
}

export function updateGuestListRequestCart({ action, cart }) {
  // GUEST_REQUEST_CLEAR_CART
  store.dispatch({
    type: 'SET_GUEST_REQUEST_LOADING',
    loading: true,
  });

  store.dispatch({
    type: 'SET_GUEST_REQUEST_ERROR',
    error: null,
  });

  const state = store.getState();
  const guestRequest = state.guestRequest;
  const currentGuestRequestCart = guestRequest && guestRequest.cart || {};

  if (action === 'GUEST_REQUEST_CLEAR_CART') {
    resetGuestListCart();
  } else {
    if (cart) {
      setGuestListCart({ ...currentGuestRequestCart, ...cart });
    }
  }

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      store.dispatch({
        type: 'SET_GUEST_REQUEST_LOADING',
        loading: false,
      });
      resolve('updated request');
    }, 0);
  });
}

export function createGuestListRequest(params) {
  store.dispatch({
    type: 'SET_GUEST_REQUEST_LOADING',
    loading: true,
  });

  store.dispatch({
    type: 'SET_GUEST_REQUEST_ERROR',
    error: null,
  });

  const state = store.getState();
  const session = state.session;

  const User = {
    ...session,
  };
  const InvitationToken = state.auth.guestListToken;

  const payload = {
    GuestListRequest: {
      InvitationToken,
      User,
      ...params,
    },
  };

  const apiConfig = {
    action: 'CREATE_GUEST_LIST_REQUEST',
    payload,
    showToast: true,
  };
  const response = api(apiConfig);
  response.then((res) => {
    store.dispatch({
      type: 'SET_GUEST_LIST_RECEIPT',
      data: res,
    });

    store.dispatch({
      type: 'SET_GUEST_REQUEST_LOADING',
      loading: false,
    });
  }).catch((error) => {
    store.dispatch({
      type: 'SET_GUEST_REQUEST_LOADING',
      loading: false,
    });
    store.dispatch({
      type: 'SET_GUEST_REQUEST_ERROR',
      error,
    });
  });

  return response;
}

export function setInstantCart(cart) {
  store.dispatch({
    type: 'SET_INSTANT_REQUEST_CART',
    cart,
  });
}

export function deleteInstantRequest() {
  // Server side is now performing clean up on expired carts,
  // We can just clear local state
  store.dispatch({
    type: 'DELETE_INSTANT_REQUEST',
  });
}

export function updateInstantRequest({ action, requestBody, outletEvent }) {
  store.dispatch({
    type: 'SET_INSTANT_REQUEST_LOADING',
    loading: true,
  });

  store.dispatch({
    type: 'SET_INSTANT_REQUEST_ERROR',
    error: null,
  });
  if (outletEvent) {
    store.dispatch({
      type: 'SET_INSTANT_REQUEST_OUTLET_EVENT',
      outletEvent,
    });
  }

  const response = api({
    action,
    payload: requestBody,
  });

  response.then((cart) => {
    if (action === 'INSTANT_REQUEST_CONFIRM_ORDER') {
      // reset cart?
      setInstantCart(null);
    } else {
      setInstantCart(cart);
    }
    store.dispatch({
      type: 'SET_INSTANT_REQUEST_LOADING',
      loading: false,
    });
  }).catch((error) => {
    store.dispatch({
      type: 'SET_INSTANT_REQUEST_LOADING',
      loading: false,
    });
    store.dispatch({
      type: 'SET_INSTANT_REQUEST_ERROR',
      error,
    });
    addToast({
      type: 'error',
      title: 'Attention: ',
      description: error,
    });
  });

  return response;
}

export function setFanRequestCart(config = {}) {
  store.dispatch({
    type: 'SET_FAN_REQUEST_CART',
    cart: config,
  });
}

export function resetFanRequestCart() {
  store.dispatch({
    type: 'RESET_FAN_REQUEST_CART',
  });
}

export function setFanRequestLoading(loading) {
  store.dispatch({
    type: 'SET_FAN_REQUEST_LOADING',
    loading: loading ? true : false,
  });
}

export function setFanRequestError(error) {
  store.dispatch({
    type: 'SET_FAN_REQUEST_ERROR',
    error: error ? error : null,
  });
}

export function setFanRequestOutletEvent(outletEvent) {
  store.dispatch({
    type: 'SET_FAN_REQUEST_OUTLET_EVENT',
    outletEvent: outletEvent ? outletEvent : null,
  });
}

export function setFanRequestSeriesSummary(seriesSummary) {
  store.dispatch({
    type: 'SET_FAN_REQUEST_SERIES_SUMMARY',
    seriesSummary: seriesSummary ? seriesSummary : null,
  });
}


/**
 * Deletes a fan request by ID and updates the application state.
 *
 * @async
 * @function deleteFanRequest
 * @param {number|string} id - The unique identifier of the fan request to delete.
 * @param {object} payload - Additional data to include in the delete request.
 * @return {Promise<object|undefined>} The API response if successful; otherwise, `undefined`.
 *
 * @description
 * - Sets the loading state to `true` and clears any existing error state before making the API call.
 * - Sends a delete request for the specified fan request using the provided `id` and `payload`.
 * - If the API responds with an error or message, the error is handled and the operation stops.
 * - On success, displays a success toast notification and resets the loading state.
 * - If an exception occurs during the operation, it is caught and handled as an API error.
 *
 * @throws {Error} Handles and displays errors from the API or unexpected failures during the operation.
 *
 * @sideEffects
 * - Updates the application's fan request loading and error states.
 * - Displays a toast notification upon success or failure.
 *
 */
export const deleteFanRequest = async (id, payload) => {
  setFanRequestLoading(true);
  setFanRequestError(null);

  try {
    const res = await gtService.deleteFanRequest(id, payload);

    // handle any errors returned from API
    if (res.error || res.message) {
      addToast({
        title: 'Error',
        description: 'There was an error. Please try again',
        type: 'error',
      });
      handleApiError(res.message || 'Unable to delete request');
      return;
    }

    addToast({
      title: 'Success',
      description: 'Request has been deleted',
      type: 'success',
      autoDelete: true,
    });

    setFanRequestLoading(false);

    return res;
  } catch (error) {
    handleApiError(error || 'Unable to delete request');
  }
};


/**
 * Edits an existing fan request by its ID with the provided payload.
 *
 * @param {string|number} id - The unique identifier of the fan request.
 * @param {Object} payload - The data to update the fan request.
 * @return {Promise<Object|undefined>} - The API response or undefined in case of an error.
 */
export const editExistingFanRequest = async (id, payload) => {
  setFanRequestLoading(true);
  setFanRequestError(null);

  try {
    const res = await gtService.editFanRequest(id, payload);
    setFanRequestLoading(false);

    if (res.error || res.message) {
      addToast({
        title: 'Error',
        description: 'There was an error. Please try again',
        type: 'error',
      });
      const errorMessage = res.message || 'Unable to edit request';
      handleApiError(errorMessage);
      return;
    }

    addToast({
      title: 'Success',
      description: 'Request has been updated',
      type: 'success',
      autoDelete: true,
    });

    return res;
  } catch (err) {
    setFanRequestLoading(false); // Ensure loading state is handled
    handleApiError(err, `API Error: Unable to edit fan request.`);
  }
};


export function updateFanSeriesRequestPriority(params = {}) {
  // example of params accepted below:
  // const {
  //   UserID: number, - REQUIRED
  //   OutletEventGroupID: number, - REQUIRED
  //   LotteryOEGRequestUUID: uuid, - REQUIRED
  //   PriorityOERequestUUID: uuid,
  // } = params;
  const state = store.getState();
  const session = state.session;
  const UserID = session && session.UserID ? Number(session.UserID) : null;

  setFanRequestLoading(true);
  setFanRequestError(null);
  const apiConfig = {
    action: 'UPDATE_USER_FAN_SERIES_PRIORITY',
    payload: {
      UserID,
      complete: true,
      ...params,
    },
  };
  const response = api(apiConfig);
  return new Promise((resolve, reject) => {
    response.then((res) => {
      store.dispatch({
        type: 'SET_USER_FAN_REQUEST_SERIES_PRIORITY',
        data: {
          LotteryOEGRequestUUID: params.LotteryOEGRequestUUID,
          TourID: params.TourID,
          PriorityOERequestUUID: params.PriorityOERequestUUID,
        },
      });

      setFanRequestLoading(false);
      resolve(res);
    }).catch((error) => {
      handleApiError(error || 'Unable to update fan series priority');
      reject(error);
    });
  });
}

const mergeLineItemsWithAddons = (items = [], addons = []) => {
  const ids = new Set(addons.map((d) => d.PriceLevelID));
  let normalizedItems = [
    ...addons,
    ...items.filter((d) => !ids.has(d.PriceLevelID)),
  ];
  if (addons.length === 0) {
    normalizedItems = normalizedItems.map((i) => {
      if (i.isAddOn) {
        i.quantity = 0;
        i.Quantity = 0;
      }
      return i;
    });
  }
  return normalizedItems;
};

/**
 * Legacy method for all updates to a fan request
 * Updates the fan request based on the provided parameters.
 * TODO: break up the updates into their own methods
 *
 * @param {Object} params - The parameters for updating the fan request.
 * @param {string} params.action - The action to be performed (e.g., 'SAVE_FAN_REQUEST').
 * @param {Object} [params.cart] - The cart details to be merged.
 * @param {Object} [params.outletEvent] - The outlet event details.
 * @param {Array} [params.addons] - The list of addons for the request.
 * @param {Object} [params.paymentMethod] - The payment method details.
 * @return {Promise<Object>} Resolves with the updated cart or API response.
 */
export function updateFanRequest(params = {}) {
  const { action, cart, outletEvent, addons, paymentMethod } = params;
  const state = store.getState();
  const { session, fanRequest } = state;
  const UserID = Number(session?.UserID) || null;

  const mergedCart = { ...fanRequest.cart, ...cart };
  const OE = outletEvent || fanRequest.outletEvent;

  setFanRequestLoading(true);
  setFanRequestError(null);

  if (cart) setFanRequestCart(mergedCart);
  if (outletEvent) setFanRequestOutletEvent(outletEvent);

  if (OE && UserID && Number.isInteger(UserID)) {
    if (['CREATE_FAN_REQUEST_WITH_SERIES_SUMMARY', 'SAVE_FAN_REQUEST'].includes(action)) {
      return handleFanRequestAction({
        action,
        OE,
        UserID,
        mergedCart,
        addons,
      });
    }
  }

  // called for actions like 'FAN_REQUEST_UPDATE_SHIPPING_ADDRESS' or 'FAN_REQUEST_UPDATE_PAYMENT_METHOD' or 'ENROLL_IN_PAYMENTPLAN'
  handleNonSaveActions({ action, mergedCart, paymentMethod });
  setFanRequestLoading(false);

  return Promise.resolve(mergedCart);
}


/**
 * Legacy method
 * Handles fan request actions that involve calling an API, such as
 * creating or saving a fan request or creating fan request with series summary.
 *
 * @param {Object} params - Parameters for handling fan request actions.
 * @param {string} params.action - The action to be performed.
 * @param {Object} params.OE - The outlet event details.
 * @param {number} params.UserID - The user ID.
 * @param {Object} params.mergedCart - The merged cart details.
 * @param {Array} [params.addons] - The list of addons for the request.
 * @return {Promise<Object>} Resolves with the API response or rejects on error.
 */
export const handleFanRequestAction = async ({ action, OE, UserID, mergedCart, addons }) => {
  const state = store.getState();

  const { OutletEventID, OutletSeriesID } = OE;
  const {
    lineItems,
    paymentList = [],
    shippingAddress,
    enrollInPaymentPlan = false,
    PriorityOERequestUUID,
  } = mergedCart;

  let payload;

  const existingRequest = state.userFanRequestSeriesSummaryList.find((r) => r.OutletEventGroupID === OE.OutletSeriesID);

  // this is called during the checkout flow on the event page (/event/:id) after selecting
  // event items and clicking on continue button
  if (action === 'CREATE_FAN_REQUEST_WITH_SERIES_SUMMARY' && OutletSeriesID && lineItems.length) {
    payload = {
      query: {
        UserID,
        OutletEventGroupID: OutletSeriesID,
      },
      oerequest: {
        UserID,
        OutletEventID,
        LotteryOEIRequests: Array.isArray(addons)
          ? mergeLineItemsWithAddons(lineItems, addons)
          : lineItems,
      },
    };
    // TODO: refactor away from this legacy pattern
    return api({ action, payload })
      .then((res) => {
        handleApiResponse({ res, action, mergedCart, addons });
        return res;
      })
      .catch((error) => {
        handleApiError(error || 'Unable to edit request');
        throw error;
      });
    // this is called when creating new request or adding to an existing request
  } else if (action === 'SAVE_FAN_REQUEST') {
    // note: payment info is not collected when adding a request to an existing oeg
    const checkoutPaymentList = getPaymentInfoForCheckout(paymentList);
    // use full payment list (selected from the three payment select dropdowns) when enrolling in payment plan,
    //  otherwise use first payment method selected from first dropdown
    const paymentInfo = enrollInPaymentPlan ? checkoutPaymentList : checkoutPaymentList.slice(0, 1);

    const outletEventItemRequests = lineItems
      .map(({ PriceLevelID, priceLevelID, Quantity, quantity, PriceLevelOptOut }) => ({
        outletEventItemRequestId: null, // defaults to null on creation
        priceLevelId: PriceLevelID || priceLevelID,
        quantity: Quantity || quantity,
        priceLevelOptOut: PriceLevelOptOut ?? false, // should default to false if price level opt out isnt selected
      }));

    payload = {
      userId: UserID,
      ...(existingRequest && { outletEventGroupRequestId: existingRequest?.LotteryOEGRequestID }),
      outletEventId: OutletEventID,
      outletEventItemRequests,
      outletEventGroupId: OutletSeriesID,
      paymentPlanEnrolled: enrollInPaymentPlan ?? false,
      ...(!existingRequest && { paymentInfo }),
      ...(!existingRequest && { shipping: shippingAddress ? getShippingAddressForCheckout(shippingAddress) : null }),
      ...(PriorityOERequestUUID && { PriorityOERequestUUID: undefined }),
    };
  }

  try {
    const fanRequestAction = existingRequest ? gtService.addToFanRequest : gtService.createFanRequest;
    const res = await fanRequestAction(payload);

    // handle error if API response returns a message property.
    if (res.message) {
      // ideally this should live in handleApiError
      addToast({
        title: 'Error',
        description: 'There was an error. Please try again',
        type: 'error',
      });
      handleApiError('Unable to handle request');
      return;
    }

    handleApiResponse({ res, action, mergedCart, addons });
    return res;
  } catch (error) {
    handleApiError(error || 'Unable to handle request');
    throw error;
  }
};


/**
 * Handles the API response and updates the fan request state accordingly.
 * This is legacy code moved into a helper method
 *
 * @param {Object} params - Parameters for handling the API response.
 * @param {Object} params.res - The API response.
 * @param {string} params.action - The action performed.
 * @param {Object} params.mergedCart - The merged cart details.
 * @param {Array} [params.addons] - The list of addons for the request.
 */
const handleApiResponse = ({ res, action, mergedCart, addons }) => {
  const adjustedCart = {
    ...mergedCart,
    lineItems: Array.isArray(addons) ? mergeLineItemsWithAddons(mergedCart.lineItems, addons) : mergedCart.lineItems,
  };
  setFanRequestCart(adjustedCart);
  setFanRequestLoading(false);

  if (action === 'CREATE_FAN_REQUEST_WITH_SERIES_SUMMARY') {
    const seriesSummary = Array.isArray(res) && res.length ? res[0] : null;
    if (seriesSummary) {
      setFanRequestSeriesSummary(seriesSummary);
    } else {
      const error = 'Unable to build series summary';
      setFanRequestError(error);
      addToast({ type: 'error', title: 'Attention:', description: error });
      throw error;
    }
  }
}


/**
 * Handles API errors
 *
 * @param {string} message - The error message to display.
 */
const handleApiError = (message) => {
  console.error(message);
  setFanRequestLoading(false);
  setFanRequestError(message);
};


/**
 * Handles non-save actions such as enrolling in a payment plan or updating payment methods.
 * This is legacy code moved into a helper method
 *
 * @param {Object} params - Parameters for handling non-save actions.
 * @param {string} params.action - The action to be performed.
 * @param {Object} params.mergedCart - The merged cart details.
 * @param {Object} [params.paymentMethod] - The payment method details.
 */
function handleNonSaveActions({ action, mergedCart, paymentMethod }) {
  if (['ENROLL_IN_PAYMENTPLAN', 'EDIT_REQUEST_LINE_ITEMS'].includes(action)) {
    setFanRequestCart(mergedCart);
  } else if (action === 'FAN_REQUEST_UPDATE_PAYMENT_METHOD' && paymentMethod) {
    const paymentList = Array.isArray(mergedCart.paymentList)
      ? updatePaymentList(mergedCart.paymentList, paymentMethod)
      : [paymentMethod];
    setFanRequestCart({ ...mergedCart, paymentList });
  }
}

/**
 * Updates the payment list with the provided payment method.
 * This is legacy code moved into a helper method
 *
 * @param {Array<Object>} paymentList - The existing list of payment methods.
 * @param {Object} paymentMethod - The new payment method to add or update.
 * @return {Array<Object>} The updated payment list.
 */
function updatePaymentList(paymentList, paymentMethod) {
  const index = paymentList.findIndex((p) => Number(p.Sort) === Number(paymentMethod.Sort));
  if (index > -1) {
    const { LotteryOEGRequestPaymentMethodUUID, LotteryOEGRequestPaymentMethodID } = paymentList[index];
    paymentList[index] = { ...paymentMethod, LotteryOEGRequestPaymentMethodUUID, LotteryOEGRequestPaymentMethodID };
  } else {
    paymentList.push(paymentMethod);
  }
  return paymentList;
}
